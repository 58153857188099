<template>
  <div
    v-click-out="() => (isOpen = false)"
    :class="['p-dropdown', `p-dropdown--${size}`]"
    :status
    :focused="isOpen"
    @click="toggleMenu"
  >
    <div class="p-dropdown__cover">
      <div v-if="modelValue.icon" class="p-dropdown--animationSlideRight">
        <p-icon
          class="p-dropdown__icon"
          :size="props.size === 'small' ? 'xmd' : 'md'"
          :icon="modelValue.icon"
        />
      </div>
      <div class="p-dropdown__inner">
        <input
          :id="id"
          v-model="modelValue.title"
          :data-test="testAttribute"
          class="p-dropdown__field"
          :name="id"
          readonly
        />
        <label
          v-if="label"
          :for="id"
          class="p-dropdown__label"
          :valid="modelValue.value ? true : false"
          v-text="label"
        />
      </div>
    </div>
    <p-menu
      v-if="isOpen"
      v-model="modelValue"
      :items="options"
      :direction="menuDirection"
      :width="menuWidth"
      @on-change="handleMenuChange"
    />
    <p-icon
      icon="caret-down"
      size="md"
      color="foreground-tertiary"
      class="p-dropdown__icon p-dropdown__icon--rotate"
    />
  </div>
</template>

<script setup lang="ts">
const isOpen = ref<boolean>(false)

interface ModelValue {
  value: string | number
  desc?: string | number
  title?: string | number
  icon?: string
}

const props = defineProps({
  id: {
    type: String,
    default: 'Provide an ID',
  },
  size: {
    type: String,
    default: 'large',
    validator: (val: string) => ['small', 'medium', 'large'].includes(val),
  },
  label: {
    type: String,
    default: '',
  },
  options: {
    type: Array as () => ModelValue[],
    required: true,
  },
  status: {
    type: String,
    default: 'selectable',
    validator: (val: string) => ['disabled', 'readonly', 'selectable'].includes(val),
  },
  menuDirection: {
    type: String,
    default: 'bottom-right',
    validator: (val: string) =>
      ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(val),
  },
  menuWidth: {
    type: String,
    default: '100%',
  },
})

const emit = defineEmits(['on-change'])

const handleMenuChange = (event: Event) => {
  isOpen.value = false
  emit('on-change', event)
}

const testAttribute = dataTest(props.id)

const modelValue = defineModel<ModelValue>('modelValue', {
  default: { icon: undefined, value: undefined, desc: undefined, title: undefined },
})

const toggleMenu = () => {
  props.status === 'selectable' && (isOpen.value = !isOpen.value)
}
</script>
