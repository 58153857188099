<template>
  <keep-alive>
    <div v-if="visible" class=""><slot /></div>
  </keep-alive>
</template>

<script setup>
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  notification: {
    type: Number,
    default: 0,
  },
  icon: {
    type: String,
    default: '',
  },
})
const selectedTab = inject('selectedTab')
const visible = computed(() => props.id === selectedTab.value)
</script>
