<template>
  <div ref="rootRef" class="p-slider" @mousedown="handleMouseDown" @touchstart="handleMouseDown">
    <span
      v-for="index in stepCount"
      :key="index"
      :class="['p-slider__bar', knobColorChange(index) && 'p-slider__bar--knobColor']"
    />
    <div class="p-slider__bar--fill" :style="`width: ${percentage}%;`">
      <span v-show="infoShow" class="p-slider__info">{{ `${percentage}%` }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  step: {
    type: Number,
    required: false,
    default: 25,
  },
  modelValue: {
    type: Number,
    required: true,
  },
})

const rootRef = ref()
const percentage = defineModel<number>('modelValue')
const stepCount = Math.ceil(100 / props.step)
const infoShow = ref(false)

const knobColorChange = (index: number) => {
  return index <= Math.ceil((percentage.value as number) / props.step)
}

const getPercentage = (e: MouseEvent | TouchEvent) => {
  e.preventDefault()
  const clickedX =
    (e instanceof MouseEvent ? e.clientX : e.touches[0].clientX) -
    rootRef.value.getBoundingClientRect().left
  const percentageCalculate = Math.ceil((clickedX / rootRef.value.offsetWidth) * 100)
  percentage.value =
    percentageCalculate < 0 ? 0 : percentageCalculate >= 100 ? 100 : percentageCalculate
}

const handleMouseMove = (e: MouseEvent | TouchEvent) => getPercentage(e)

const handleMouseUp = () => {
  infoShow.value = false
  document.removeEventListener('mousemove', handleMouseMove)
  document.removeEventListener('mouseup', handleMouseUp)
  document.removeEventListener('touchmove', handleMouseMove)
  document.removeEventListener('touchend', handleMouseUp)
}

const handleMouseDown = (e: MouseEvent | TouchEvent) => {
  infoShow.value = true
  getPercentage(e)
  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp)
  document.addEventListener('touchmove', handleMouseMove)
  document.addEventListener('touchend', handleMouseUp)
}
</script>
