<template>
  <div class="p-menu" :direction :style="{ width: width }">
    <div
      v-for="item in items"
      :key="item.value"
      class="p-menu__item"
      @click.prevent.stop="itemClickHandler(item)"
    >
      <div class="p-menu__item__inner">
        <div>
          <div class="p-menu__item--title">
            <p-icon v-if="item.icon" :icon="item.icon" size="md" />
            <div>
              <h4 v-text="item.title" />
              <div v-if="item.desc" class="p-menu__item--desc" v-text="item.desc" />
            </div>
          </div>
        </div>
        <p-icon v-if="modelValue.value === item.value" icon="check" size="md" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface ModelValue {
  value: string | number
  desc?: string | number
  title?: string | number
  icon?: string
}

defineProps({
  width: {
    type: String,
    default: '100%',
  },
  items: {
    type: Array as () => ModelValue[],
    required: true,
  },
  direction: {
    type: String,
    default: 'bottom-right',
    validator: (val: string) =>
      ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(val),
  },
})

const emit = defineEmits(['on-change'])

const modelValue = defineModel<ModelValue>('modelValue', {
  default: { icon: undefined, value: undefined, desc: undefined, title: undefined },
})

const itemClickHandler = (item: ModelValue) => {
  modelValue.value = item
  emit('on-change')
}
</script>
