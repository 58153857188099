<template>
  <div class="p-tab">
    <div class="p-tab__wrapper" :size="size">
      <div class="place-items-center gap-16">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['p-tab__item', { 'p-tab__item--active': selectedTab === tab?.id }]"
          @click="handleTabClick(tab, index)"
        >
          <p-icon
            v-if="tab?.icon"
            :icon="tab?.icon"
            :size="size === 'lg' ? 'md' : 'sm'"
            class="p-tab__item--icon"
            color="foreground-accent"
          />
          <div v-else class="p-tab__item--container">
            <p class="p-tab__item--text" v-text="tab?.title" />
            <p-badge
              v-if="tab?.notification > 0"
              :text="tab?.notification"
              color="success"
              size="lg"
            />
          </div>
        </div>
      </div>
      <p-tooltip v-if="tabs[selected]?.tooltip" direction="left">
        <p-icon v-if="icon" :icon="icon" size="sm" color="foreground-secondary" />
        <template #content> {{ tabs[selected]?.tooltip }} </template>
      </p-tooltip>
    </div>
    <div class="p-tab__content">
      <keep-alive>
        <slot />
      </keep-alive>
    </div>
  </div>
</template>

<script setup>
import { useSlots } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 0,
  },
  selected: {
    type: [Number],
    default: 0,
  },
  size: {
    type: String,
    default: 'md',
  },
  icon: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue', 'tab-selected'])

const handleTabClick = (tab, index) => {
  selectedTab.value = tab?.id
  selectedIndex.value = index
  value.value = tab?.id
  emit('tab-selected', tab?.id)
}

const slots = useSlots()

const tabs = computed(() => (slots.default ? slots.default() : []).map((tab) => tab.props))
const selectedTab = ref(tabs.value[props.selected]?.id)
const selectedIndex = ref(props.selected)
provide('selectedTab', selectedTab)

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit && emit('update:modelValue', val),
})

watch(props.modelValue, () => {
  selectedTab.value = value
})

watch(props.selected, (val) => {
  selectedTab.value = val
})
</script>
