<template>
  <div
    class="p-tooltip"
    :direction="direction"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <div class="p-tooltip__initiator">
      <slot />
    </div>

    <div v-if="showTooltip" class="p-tooltip__item">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
const showTooltip = ref<boolean>(false)
defineProps({
  direction: {
    type: String,
    default: 'bottom',
    validator: (val: string) =>
      [
        'left',
        'right',
        'top',
        'top-left',
        'top-right',
        'bottom',
        'bottom-left',
        'bottom-right',
      ].includes(val),
  },
})
</script>
